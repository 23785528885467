import { authService } from "@/services/api/authService"
import { useAuthStore } from "@/stores/authStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

export default () => {
    const router = useRouter();
    const authStore = useAuthStore();
    const { setUser, getToken, refreshJwt, setLoading } = authStore;
    const { user, loading, jwt, currentPage } = storeToRefs(authStore);

    const createLogin = async (body) => {
        clearSession();
        setLoading(true);
        const { data } = await authService.signIn(body);

        const { jwt, id_user, company_id, user_name, store_id, menu, tab, photo_url } = data;

        sessionStorage.setItem('token', jwt);
        refreshJwt();
        setUser({
            userId: id_user,
            name: user_name,
            menu,
            companyId: company_id,
            storeId: store_id,
            tab,
            photoUrl: photo_url
        });
        setLoading(false);

        localStorage.setItem('loggedIn', true);
        router.push('/');

        return data;
    }

    const refreshUserInfo = async (token) => {
        setLoading(true);
        const { data } = await authService.fetchUserByJwt(token);

        const { jwt, id_user, user_name, menu, company_id, store_id, tab, photo_url } = data;

        sessionStorage.setItem('token', jwt);
        refreshJwt();

        setUser({
            userId: id_user,
            name: user_name,
            menu,
            companyId: company_id,
            storeId: store_id,
            tab,
            photoUrl: photo_url
        });

        setLoading(false);

        return data;
    }

    const clearSession = () => {
        sessionStorage.removeItem("token");
        jwt.value = null;
        refreshJwt();
    }

    const logout = async () => {
        clearSession();
        const token = getToken();

        await authService.logoutUser(user.value.userId, token);
        router.push("auth/login");
    }

    const changeStore = async (token, store) => {
        const { data } = await authService.changeStore(token, store.store_id);

        const { jwt, id_user, company_id, user_name, menu, store_id, tab, photo_url } = data;

        sessionStorage.setItem('token', jwt);
        refreshJwt();
        setUser({
            userId: id_user,
            name: user_name,
            companyId: company_id,
            storeId: store_id,
            menu,
            tab,
            photoUrl: photo_url
        });

        return data;
    }

    return {
        createLogin,
        user,
        logout,
        refreshJwt,
        getToken,
        refreshUserInfo,
        clearSession,
        changeStore,
        loading,
        currentPage
    }
}