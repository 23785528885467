import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuthStore = defineStore('authStore', () => {
    const jwt = ref(null);
    const user = ref({
        userId: '',
        name: '',
        companyId: '',
        storeId: '',
        menu: [],
        tab: [],
        avatar: '',
        photoUrl: ''
    });
    const loading = ref(false);
    const currentPage = ref(null);

    const refreshJwt = () => {    
        jwt.value = sessionStorage.getItem("token");

        return jwt.value;
    }

    const getToken = () => {
        return refreshJwt();
    }

    const putUserAttribute = (key, value) => {
        user.value[key] = value;
    }

    function setUser(data) {
        user.value = data;
    }

    function setLoading(value) {
        loading.value = value;
    }

    return {
        jwt,
        refreshJwt,
        getToken,
        putUserAttribute,
        user,
        setUser,
        loading,
        setLoading,
        currentPage
    }
});