import apiLinkHub from "@/api/linkhub";

export const authService = {
    signIn({ email, password }) {
        return apiLinkHub.post('/users/login', { email, password });
    },
    fetchUserByJwt(jwt) {
        return apiLinkHub.post('/users/login', { jwt });
    },
    changeStore(jwt, store) {
        return apiLinkHub.post('/users/login', { jwt, store_id: store });
    },
    logoutUser(userId, jwt) {
        return apiLinkHub.delete(`/users/logout/${userId}`, { jwt });
    }
}