import { createRouter, createWebHistory } from 'vue-router';
import useAuth from '@/composables/useAuth';
import { useSidebarStore } from '@/stores/sidebarStore';

const routes = [
  {
    path: '/',
    name: 'breadcrumb.home',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/AppDashboard.vue')
      }
    ]
  },
  {
    path: '/products',
    name: 'breadcrumb.products',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Products/AppProducts.vue'),
      },
      {
        path: 'details',
        name: 'breadcrumb.register_product',
        component: () => import('@/views/Products/AppProductDetails.vue')
      }
    ]
  },
  {
    path: '/company',
    name: 'breadcrumb.company',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Company/AppCompany.vue'),
      },
      {
        path: 'stores',
        name: 'breadcrumb.company_tabs.stores',
        component: () => import('@/views/Company/AppCompanyStores.vue')
      },
      {
        path: 'branches',
        name: 'breadcrumb.company_tabs.branches',
        component: () => import('@/views/Company/AppCompanyBranches.vue')
      }
    ]
  },
  {
    path: '/users',
    name: 'breadcrumb.users',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Users/AppUsers.vue')
      },
      {
        path: 'profiles',
        name: 'breadcrumb.users_profiles',
        component: () => import('@/views/Users/appUserProfiles.vue')
      }
    ]
  },
  {
    path: '/auth',
    name: 'breadcrumb.auth',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'breadcrumb.auth_login',
        component: () => import('@/views/Auth/AppLogin.vue')
      },
      {
        path: 'reset-password',
        name: 'breadcrumb.reset_password',
        component: () => import('@/views/Auth/AppResetPassword.vue')
      },
      {
        path: 'signup',
        name: 'breadcrumb.signup',
        component: () => import('@/views/Auth/AppSignup.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/layouts/AppLayout.vue')
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, _from, next) => {
  const { getToken, user, refreshUserInfo, logout, currentPage } = useAuth();
  const { menuItemsList } = useSidebarStore();
  const token = getToken();

  const allowedAuthPages = ['breadcrumb.auth_login', 'breadcrumb.reset_password', 'breadcrumb.signup'];

  if (!allowedAuthPages.includes(to.name) && !token) next({ name: 'breadcrumb.auth_login' });
  else if (to.name === 'breadcrumb.auth_login' && token) next({ name: 'breadcrumb.home' });
  else {
    const token = getToken();
    try {
      const loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
      if (token && !loggedIn) await refreshUserInfo(token);
      else localStorage.removeItem("loggedIn");
    } catch (_e) {
      logout();
    }

    const pathStructure = to.path.split("/").slice(1)

    const currentPageMenu = menuItemsList.find(m => m.route == `/${pathStructure[0]}`);

    if (user.value.tab.length > 0) {
      const tabs = user.value.tab.filter(t => t?.feature_id == currentPageMenu?.feature_id);
      currentPage.value = { ...currentPageMenu, tabs };
    }

    next();
  }
})

export default router;