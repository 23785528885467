import { useAuthStore } from '@/stores/authStore';
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.linkhub.com.br/api/v1/'
});

api.interceptors.request.use((request) => {
  const { getToken } = useAuthStore();

  const token = getToken();

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});


export default api;