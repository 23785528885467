//Store para poder controlar a sidebar pelo componente header e sidebar

import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSidebarStore = defineStore('sidebar', () => {
    const isOpen = ref(false);
    const isHovered = ref(false);

    function toggleSidebar() {
        isOpen.value = !isOpen.value;
    }

    function closeSidebarOn() {
        if (isOpen.value) {
            toggleSidebar();
            isHovered.value = false;
        }
    }

    const menuItemsList = [
        { icon: 'heroicons-outline:home', label: 'Home', feature_id: "home", route: '/', color: '#9AB7D3' },
        { icon: 'mdi:company', label: 'sidebar.item_company', feature_id: "enterprise", route: '/company', color: '#9AB7D3' },
        { icon: 'fluent:box-24-regular', label: 'sidebar.item_products', feature_id: "product", route: '/products', color: '#FFB56F' },
        { icon: 'cuida:user-outline', label: 'sidebar.item_users', feature_id: "users", route: '/users', color: '#DB99F2' },
        { icon: 'quill:paper', label: 'sidebar.item_orders', feature_id: "orders", route: '/orders', color: '#55CBCD' },
        { icon: 'lineicons:gear-1', label: 'sidebar.item_config', feature_id: "config", route: '/config', color: '#F8C1BA' },
        { icon: 'mdi:cloud-cog-outline', label: 'sidebar.item_app', feature_id: "app",  route: '/apps', color: '#F7D2AF' },
        { icon: 'ic:outline-live-help', label: 'sidebar.item_help', feature_id: "help", route: '/help', color: '#4F4F4F' },
    ];

    return { isOpen, isHovered, toggleSidebar, closeSidebarOn, menuItemsList };
}); 